import { useTranslations } from "next-intl";
import { Flex } from "@radix-ui/themes";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import Link from "next/link";
import React from "react";

function GoToFaqCta() {
  const t = useTranslations("FAQ");
  return (
    <Flex direction={"column"} align={"center"}>
      <ButtonV2 variant={"outline"} color={"gray"} size={"3"} asChild>
        <Link href="/support">{t("go_to_faq")}</Link>
      </ButtonV2>
    </Flex>
  );
}

export { GoToFaqCta };
