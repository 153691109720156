"use client";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import React from "react";
import { FaqSection } from "@components/Faq/FaqSection";
import Image from "next/image";
import duck from "/public/images/duck.gif";
import styles from "@components/Faq/SupportLayout.module.scss";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { NonDesktopView } from "@/design-system/responsive-helpers/NonDesktopView";
import { Flex } from "@radix-ui/themes";
import { GoToFaqCta } from "@components/Faq/GoToFaqCta";
import { MediaTextPane } from "@/design-system/components/MediaTextPane/MediaTextPane";
import { useTranslations } from "next-intl";
import { FrequentlyAskedCategoryWithFaqs } from "@/api/api.directory.faq";

type ClientFaqProps = {
  faq: FrequentlyAskedCategoryWithFaqs[];
};

function ClientFaq({ faq }: ClientFaqProps) {
  const t = useTranslations("FAQ");
  return (
    <LayoutContainerV2
      style={{ backgroundColor: "var(--colorV2-beige" }}
      size={"3"}
    >
      <MediaTextPane
        my={"9"}
        media={
          <Image
            src={duck}
            priority={true}
            alt={""}
            className={styles.responsiveImage}
          />
        }
        text={
          <>
            <HeadingV2
              textStyle={"Headline XL"}
              align={{ initial: "center", md: "left" }}
              as={"h2"}
            >
              {t("title")}
            </HeadingV2>
            <NonDesktopView>
              <Flex align={"center"} justify={"center"}>
                <Image
                  src={duck}
                  priority={true}
                  alt={""}
                  className={styles.responsiveImage}
                />
              </Flex>
            </NonDesktopView>
            <FaqSection faqCategories={faq} scrollToActiveQuestion={false} />
          </>
        }
        bottom={<GoToFaqCta />}
      />
    </LayoutContainerV2>
  );
}

export { ClientFaq };
